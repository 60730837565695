import { zodResolver } from '@hookform/resolvers/zod';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Modal, TextField, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { forgotPasswordModalOpen, loginModalClose, postPageAudit, postTeacherLogin, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const Login = () => {
    var _a, _b;
    const dispatch = useDispatch();
    const classes = useStyle();
    const [showPassword, setShowPassword] = useState(false);
    const { modals: { loginModal }, loader: { loginSignUpProgress }, } = useSelector((state) => state.auth);
    const handleClose = () => {
        dispatch(postPageAudit({ page: 'TEACHER PORTAL:Login Modal Close' }));
        dispatch(loginModalClose());
    };
    const handleLogIn = (data) => {
        dispatch(postPageAudit({ page: 'TEACHER PORTAL:Login Button Click' }));
        dispatch(postTeacherLogin({
            loginID: data.loginID,
            password: data.password,
        }));
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const formValidation = z.object({
        loginID: z.string().email().nonempty('Username is required.'),
        password: z.string().nonempty('Password is required.'),
    });
    const loginForm = useForm({
        defaultValues: { loginID: '', password: '' },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const { register, handleSubmit, formState: { errors }, reset, } = loginForm;
    useEffect(() => {
        reset();
    }, [loginModal]);
    return (loginModal && (React.createElement(Modal, { open: loginModal, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement("div", { className: classes.loginHeader }, "Login"),
                React.createElement(IconButton, { onClick: handleClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement("div", { className: classes.loginBody },
                React.createElement(Typography, null, "Username"),
                React.createElement(TextField, Object.assign({ variant: "outlined", type: "email", size: "small", fullWidth: true }, register('loginID'), { error: !!errors.loginID, helperText: (_a = errors.loginID) === null || _a === void 0 ? void 0 : _a.message })),
                React.createElement(Typography, null, "Password"),
                React.createElement(TextField, Object.assign({ variant: "outlined", type: showPassword ? 'text' : 'password', size: "small", InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                    }, fullWidth: true }, register('password'), { error: !!errors.password, helperText: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message })),
                React.createElement(Typography, { variant: "caption", className: classes.forgotPassword, onClick: () => {
                        dispatch(postPageAudit({
                            page: 'TEACHER PORTAL:FORGOT PASSWORD MODAL OPEN',
                        }));
                        dispatch(forgotPasswordModalOpen());
                    } }, "Forgot your password?"),
                React.createElement(Button, { variant: "contained", fullWidth: true, onClick: handleSubmit(handleLogIn), disabled: loginSignUpProgress }, "LOGIN"))))));
};
export default Login;
