/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyle } from '../styles/style';
import Logo from '../containers/images/logo';
const NewFooter = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    return (React.createElement("div", { className: classes.newFooterMain },
        React.createElement("div", null,
            React.createElement(Logo, { onClick: () => navigate('/') }),
            React.createElement(Typography, { fontSize: "12px" }, "Zonatech Digital Solution Pvt Ltd."),
            React.createElement(Typography, { fontSize: "12px" }, "Mail:info@zonatechdigital.com")),
        React.createElement("div", null,
            React.createElement(Typography, { fontSize: "16px", fontWeight: "500" }, "Useful Links"),
            React.createElement(Typography, { fontSize: "12px" }, "Home"),
            React.createElement(Typography, { fontSize: "12px" }, "Exams Curated"),
            React.createElement(Typography, { fontSize: "12px" }, "Contact Us")),
        React.createElement("div", null,
            React.createElement(Typography, { fontSize: "16px", fontWeight: "500" }, "Follow Us"),
            React.createElement(Typography, null, "Copyright 2024 Zonatech Digital Private Limited"))));
};
export default NewFooter;
