import { createTheme } from '@mui/material';
import { pxToRem } from '../utils/themeUtils';
export const theme = createTheme({
    palette: {
        primary: {
            main: '#00a2ed',
            contrastText: '#ffffff',
            light: '#D0EFFC',
            dark: '#00a2ed',
        },
        secondary: {
            main: '#FC7500',
            light: '#fee3cc',
            dark: '#feb8a0',
        },
        common: {
            white: 'rgba(255,255,255,1)',
            black: 'rgba(0,0,0,1)',
        },
        background: {
            default: 'rgba(255,255,255,1)',
            paper: 'rgba(255,255,255,1)',
        },
        text: {
            primary: '#1E2D3D',
            secondary: '#464b50',
            disabled: '#b0bcc8',
        },
        divider: '#E7ECF3',
        success: {
            main: '#42b06e',
            dark: '#A1D8B7',
            light: '#d9efe2',
        },
        info: {
            light: 'rgba(20,175,241,0.2)',
            main: 'rgba(20,175,241,1)',
            dark: 'rgba(20,175,241,0.3)',
        },
        warning: {
            main: '#FFB800',
            light: '#FFF1CC',
            dark: '#FFDC80',
        },
        error: {
            main: '#EC4F4F',
            light: '#F8DCDC',
            dark: '#F6A7A7',
        },
    },
    typography: {
        fontFamily: `'Inter','Roboto'`,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        fontWeightRegular: 400,
        h1: {
            fontSize: pxToRem(30),
            fontWeight: 500,
            lineHeight: pxToRem(44),
        },
        h2: {
            fontSize: pxToRem(22),
            fontWeight: 500,
            lineHeight: pxToRem(33),
        },
        h3: {
            fontSize: pxToRem(18),
            fontWeight: 500,
            lineHeight: pxToRem(27),
        },
        h4: {
            fontSize: pxToRem(28),
            fontWeight: 500,
        },
        h5: {
            fontSize: pxToRem(24),
            fontWeight: 500,
        },
        h6: {
            fontSize: pxToRem(20),
            fontWeight: 500,
        },
        body1: {
            fontSize: pxToRem(15),
            fontWeight: 400,
            lineHeight: pxToRem(23),
        },
        body2: {
            fontSize: pxToRem(15),
            fontWeight: 500,
            lineHeight: pxToRem(23),
        },
        subtitle1: {
            fontSize: pxToRem(14),
            fontWeight: 400,
            lineHeight: pxToRem(21),
        },
        caption: {
            fontSize: pxToRem(12),
            fontWeight: 400,
            lineHeight: pxToRem(18),
        },
        button: {
            fontSize: pxToRem(14),
            fontWeight: 400,
            lineHeight: pxToRem(20),
            textTransform: 'none',
        },
        overline: {
            fontSize: pxToRem(12),
            fontWeight: 600,
            lineHeight: pxToRem(18),
            textTransform: 'none',
        },
    },
});
