import { ArrowForward, PlayCircleOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../../containers/images/logo';
import { loginModalOpen, signUpModalOpen } from '../../store/actions';
import { useStyle } from '../../styles/style';
import Background from './Background';
import HomeImage from './HomeImage';
const NewLandingMain = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLoginClick = () => {
        dispatch(loginModalOpen());
    };
    const handleSignUpClick = () => {
        dispatch(signUpModalOpen());
    };
    return (React.createElement("div", null,
        React.createElement(Background, null,
            React.createElement("div", { className: classes.newHeader },
                React.createElement(Logo, { onClick: () => navigate('/') }),
                React.createElement("div", { style: { display: 'flex', flexDirection: 'row', gap: '1rem' } },
                    React.createElement(Button, { size: "small" }, "Home"),
                    React.createElement(Button, { style: { color: 'black' }, size: "small" }, "Exams Curated"),
                    React.createElement(Button, { style: { color: 'black' }, size: "small" }, "Contact"),
                    React.createElement(Button, { style: { color: 'black' }, onClick: handleLoginClick, size: "small" }, "Log In"),
                    React.createElement(Button, { variant: "contained", size: "small", onClick: handleSignUpClick, style: { backgroundColor: '#4154F1' } }, "Sign Up"))),
            React.createElement("div", { className: classes.newHomeMid },
                React.createElement(Stack, { spacing: "2rem" },
                    React.createElement("div", null,
                        React.createElement(Typography, { fontSize: "24px", lineHeight: "29px", fontWeight: "600", color: "red" }, "Hey Teacher !!!"),
                        React.createElement(Typography, { fontSize: "24px", lineHeight: "29px", fontWeight: "600", color: "#3D467E" }, "Earn for Lifetime \u2013 Your Questions, Your Profits!")),
                    React.createElement(Stack, { spacing: "1rem", direction: "row" },
                        React.createElement(Button, { style: {
                                backgroundColor: '#4154F1',
                                padding: '0.75rem 1.25rem',
                            }, size: "large", variant: "contained", endIcon: React.createElement(ArrowForward, null), onClick: handleSignUpClick }, "Get Started"),
                        React.createElement(Button, { size: "large", style: {
                                color: '#3D467E',
                                fontWeight: 600,
                                padding: '0.75rem 1.25rem',
                            }, startIcon: React.createElement(PlayCircleOutlined, { style: { color: '#4154F1' } }) }, "Watch Video"))),
                React.createElement(HomeImage, null)))));
};
export default NewLandingMain;
