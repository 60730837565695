import { produce } from 'immer';
import { PORTALS } from '../containers/constants';
import { FORGOT_PASSWORD_MODAL_CLOSE, FORGOT_PASSWORD_MODAL_OPEN, GET_TENANT_CODE_SUCCESS, LOGIN_MODAL_CLOSE, LOGIN_MODAL_OPEN, POST_TEACHER_LOGIN, POST_TEACHER_LOGIN_SUCCESS, POST_TEACHER_SIGN_UP, POST_TEACHER_SIGN_UP_FAILURE, POST_TEACHER_SIGN_UP_SUCCESS, RESET_PASSWORD_MODAL_CLOSE, RESET_PASSWORD_MODAL_OPEN, SIGNUP_MODAL_CLOSE, SIGNUP_MODAL_OPEN, USER_SIGN_OUT, } from './authConstants';
const initialState = {
    resetPasswordModal: false,
    upcomingExamLoader: true,
    user: { loggedIn: false },
    loader: { loginSignUpProgress: false },
    modals: {
        loginModal: false,
        signUpModal: false,
        forgotPasswordModal: false,
    },
    code: '',
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export const authReducer = (state = initialState, action) => produce(state, () => {
    switch (action.type) {
        case USER_SIGN_OUT: {
            return Object.assign(Object.assign({}, state), { user: { loggedIn: false } });
        }
        case RESET_PASSWORD_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { resetPasswordModal: true });
        }
        case RESET_PASSWORD_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { resetPasswordModal: false });
        }
        case POST_TEACHER_LOGIN_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { loginSignUpProgress: false }), modals: Object.assign(Object.assign({}, state.modals), { loginModal: false }), user: Object.assign(Object.assign({}, action.payload), { loggedIn: true, userType: PORTALS.TEACHER_PORTAL }) });
        }
        case LOGIN_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: true, signUpModal: false }) });
        }
        case LOGIN_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: false }) });
        }
        case SIGNUP_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { signUpModal: true, loginModal: false }) });
        }
        case SIGNUP_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { signUpModal: false }) });
        }
        case FORGOT_PASSWORD_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { forgotPasswordModal: true, loginModal: false }) });
        }
        case FORGOT_PASSWORD_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { forgotPasswordModal: false }) });
        }
        case GET_TENANT_CODE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { code: action.payload.tenantCode });
        }
        case POST_TEACHER_LOGIN: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { loginSignUpProgress: true }) });
        }
        case POST_TEACHER_SIGN_UP: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { loginSignUpProgress: true }) });
        }
        case POST_TEACHER_SIGN_UP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { loginSignUpProgress: false }) });
        }
        case POST_TEACHER_SIGN_UP_FAILURE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { loginSignUpProgress: false }) });
        }
        default:
            return state;
    }
});
