import React from 'react';
import { useSelector } from 'react-redux';
import { useStyle } from '../styles/style';
import Body from './Body';
import Header from './Header';
import Modals from './Modals';
import NewFooter from './NewFooter';
const MainComponent = () => {
    const classes = useStyle();
    const { user } = useSelector((state) => state.auth);
    return (React.createElement("div", { className: classes.main },
        user && (user === null || user === void 0 ? void 0 : user.tenantID) && React.createElement(Header, null),
        React.createElement(Body, null),
        React.createElement(NewFooter, null),
        React.createElement(Modals, null)));
};
export default MainComponent;
